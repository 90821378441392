import React from "react"
import Layout from "../components/layout"
import MainImage from "../components/mainImage"
import interactiveMap from "../../static/interactive-map.png"
import interactiveMapIcon from "../../static/interactive-map-icon.svg"
import MontagueCountyTexas from "../../static/pdf-maps/MontagueCountyTexas.pdf"
import ReevesCountyTexas from "../../static/pdf-maps/ReevesCountyTexas.pdf"
import WyomingPecan from "../../static/pdf-maps/PECAN_WY.pdf"
import Verde36 from "../../static/pdf-maps/Verde36.pdf"
import Mountrail from "../../static/pdf-maps/Mountrail.pdf"
import mapBg from "../../static/maps/mi-bg.png"
import { Helmet } from "react-helmet"

export default function contact(props) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>pecan pipeline - Maps</title>
      </Helmet>
      <MainImage header="General Maps of Gas Gathering Systems" src={mapBg} />
      <h2 className="page-content-title fs5">Gas Gathering System Locations</h2>
      <div className="maps-page page-content-container fbx-cc fs2">
        Click on a location to view the PDF map for a specific gas gathering
        system.
        <div className="interactive-map">
          <img alt="map" src={interactiveMap} />
          <Marker
            style={{ left: "34.2%", top: "8%" }}
            description="Stanley Gas Gathering System"
            path={Mountrail}
          />
          <Marker
            style={{ left: "28.6%", top: "63.7%" }}
            description="Zena Gas Gathering System"
            path={ReevesCountyTexas}
          />
          <Marker
            style={{ left: "46.7%", top: "50.7%" }}
            description="Kripple Kreek System"
            path={MontagueCountyTexas}
          />

          <Marker
            style={{ left: "29.4%", top: "31.7%" }}
            description="Wyoming Pecan System"
            path={WyomingPecan}
          />

          <Marker
            style={{ left: "26.7%", top: "26.3%" }}
            description="Wyoming Pecan System"
            path={WyomingPecan}
          />

          <Marker
            style={{ left: "29.4%", top: "23.7%" }}
            description="Wyoming Pecan System"
            path={WyomingPecan}
          />

          <Marker
            style={{ left: "41%", top: "85%" }}
            description="Verde Natural Gas Pipeline System"
            path={Verde36}
          />
          <Marker
            style={{ left: "43.5%", top: "87.5%" }}
            description="Verde Natural Gas Pipeline System"
            path={Verde36}
          />
          <Marker
            style={{ left: "45%", top: "84%" }}
            description="Verde Natural Gas Pipeline System"
            path={Verde36}
          />
          <Marker
            style={{ left: "47.4%", top: "85%" }}
            description="Verde Natural Gas Pipeline System"
            path={Verde36}
          />
        </div>
      </div>
    </Layout>
  )
}

function Marker({ description = "", style = {}, path = "#" }) {
  return (
    <div style={style} className="interactive-map-marker">
      <a href={path} target="_blank" rel="noopener noreferrer">
        <img style={{ width: "28x" }} src={interactiveMapIcon} alt="map icon" />
      </a>
      <div className="description">{description}</div>
    </div>
  )
}
